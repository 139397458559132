<template>
  <div class="main">
    <div class="banner"></div>
    <div class="summary content">
      <div class="adv">
        <div>产品概述</div>
        <div class="sub">
          欣网B2B2C多用户商城系统，打造支持自营+招商入驻经营模式的电商平台<br />
          支持运营、招商等多种运营模式，让企业低成本快速构建网上商城系统，打造大型电商平台
        </div>
      </div>
      <div class="introduce">
        <div>独立部署</div>
        <div>个性化定制</div>
        <div>功能齐全</div>
        <div>按需选择</div>
      </div>
      <div class="card_group">
        <div class="introduce_img">
          <img
            src="../../assets/img/productList/b2b2cMail/details_introduce_img_2.jpg"
            alt="自营线上商城+自身多渠道业务整合"
          />
          <div class="bottom">自营线上商城+自身多渠道业务整合</div>
        </div>
        <div class="introduce_img">
          <img
            src="../../assets/img/productList/b2b2cMail/details_introduce_img_1.jpg"
            alt="建立线上商城+签约多方商户销售"
          />
          <div class="bottom">建立线上商城+签约多方商户销售</div>
        </div>
      </div>
      <div class="btn_lgd">
        <div class="text">类似京东、天猫的经营模式</div>
      </div>
    </div>
    <div class="adt">
      <div class="adv">
        <div>特色&优势</div>
        <div class="sub">多年需求定制及技术沉淀 形成我们特有的优势特色</div>
      </div>
      <div class="grid_group">
        <div v-for="i in advList" :key="i.id">
          <img :src="i.img" :alt="i.title" />
          <div class="title">{{ i.title }}</div>
          <div class="stp"></div>
          <div>
            {{ i.text }}
          </div>
        </div>
      </div>
    </div>
    <div class="content mid">
      <div class="adv">
        <div>多终端数据统一</div>
        <div class="sub">打造企业级独立部署的电商平台</div>
      </div>
      <div class="btn_lgd">
        <div class="text">多场景适配</div>
      </div>
      <div>
        <img
          src="../../assets/img/productList/b2b2cMail/details_unified.png"
          alt="多场景适配"
        />
      </div>
      <div class="adv">
        <div>运营模式及架构</div>
        <div class="sub">
          从商家入驻到店铺搭建、消费者采购、配送等环节，构建了一个完整的电商交易链<br />
          通过分销+拼团等促销功能，帮助商家和平台快速扩展销售渠道，打造多种盈利模式
        </div>
      </div>
      <div class="card_group">
        <div class="mode_img" v-for="i in opeList" :key="i.id">
          <div class="img">
            <img :src="i.img" :alt="i.title" />
          </div>

          <div class="ball"></div>
          <div class="title">{{ i.title }}</div>
          <div class="sub">{{ i.subTitle }}</div>
        </div>
      </div>
    </div>
    <div class="fun">
      <div class="content">
        <div class="title_img"></div>
        <div class="title_text">产品功能</div>
        <div class="function_group">
          <div class="function_menu">
            <div class="menu" v-for="(i, index) in functionList" :key="i.id">
              <div class="menu_title">{{ i.title }}</div>
              <div class="menu_list">
                <div
                  class="menu_item"
                  v-for="(item, itemIndex) in i.list"
                  :key="item.id"
                  @click="chooseFunctionItem(index, itemIndex)"
                  :class="
                    Findex == index && FitemIndex == itemIndex ? 'active' : ''
                  "
                >
                  {{ item.name }}
                </div>
              </div>
            </div>
          </div>
          <div class="function_introduce">
            <div class="heade">
              <div class="gold_title_right">
                <div class="text">
                  {{ functionList[Findex].list[FitemIndex].name }}
                </div>
              </div>
            </div>

            <div class="img">
              <img :src="functionList[Findex].list[FitemIndex].img" :alt="functionList[Findex].list[FitemIndex].name" />
            </div>
            <div class="function_text">
              {{ functionList[Findex].list[FitemIndex].text }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="serive">
      <div class="adv">
        <div>服务支持</div>
        <div class="sub">为您提供完善专业的服务</div>
      </div>
      <div class="content card_group">
        <div class="serive_card" v-for="i in seriveList" :key="i.id">
          <div class="img">
            <img :src="i.img" :alt="i.title" />
          </div>
          <div class="text">
            <div class="bold">{{ i.title }}</div>
            <div>{{ i.text }}</div>
          </div>
        </div>
      </div>

      <div class="content swiper">
        <div class="title">客户案例</div>
        <swiper ref="mySwiper" :options="swiperOptions">
          <swiper-slide v-for="i in 3" :key="i">
            <div class="slide_wrap">
              <div class="text">
                <div class="bule_title_left">
                  <div class="bule_title_text">山东移动商城</div>
                </div>
                <div class="text_list">
                  “中国移动通信集团山东有限公司隶属于中国移动通信集团公司，公司下设17个市级公司；目前拥有3000万+用户体系；向全山东移动用户提供优质的服务。
                </div>
                <div class="text_list">
                  移动商城对接移动内部能力中心，将用户订单，服务等数据上传到移动核心系统并快速下发到服务单位；在提供商品销售的同时，提供了更优质的服务。
                </div>
                <div class="text_list">
                  将移动原有的功能业务及产品在商城上进行展示，增加了山东移动主体及各地市的营收，同时将更多优质的服务提供给了山东移动用户。
                </div>
              </div>
              <div class="img">
                <img src="../../assets/img/productList/b2b2cMail/details_case_img_1.png" alt="中国移动">
              </div>
            </div>
          </swiper-slide>
          <div class="swiper-button-prev" slot="button-prev"></div>
          <!-- <div class="swiper-pagination" slot="pagination"></div> -->
          <div class="swiper-button-next" slot="button-next"></div>
        </swiper>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      Findex: 0,
      FitemIndex: 0,
      swiperOptions: {     
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        loop: true,
      },
      advList: [
        {
          id: 1,
          img: require("../../assets/img/productList/b2b2cMail/details_advantage_icon_1.png"),
          title: "独立后台管理",
          text:
            "独立后台，可统一管理PC端/APP/微商城/H5等多个终端，帮助平台运营者全方位掌握商城动态",
        },
        {
          id: 2,
          img: require("../../assets/img/productList/b2b2cMail/details_advantage_icon_2.png"),
          title: "可视化编辑",
          text:
            "经过专业设计设计师的设计与搭配，提供多样化的楼层及店铺设计,装修店铺像搭积木一样简单",
        },
        {
          id: 3,
          img: require("../../assets/img/productList/b2b2cMail/details_advantage_icon_3.png"),
          title: "界面精美体检极佳 ",
          text:
            "站在用户的立场，注重终端交互体验，打造真正符合商家和用户习惯的商城，兼顾实用性，体验极佳",
        },
        {
          id: 4,
          img: require("../../assets/img/productList/b2b2cMail/details_advantage_icon_4.png"),
          title: "易扩展",
          text:
            "具备极高的拓展性，可对接各类内/外部系统，很好的融入各类系统中，也可拓展更加丰富的功能模块",
        },
        {
          id: 5,
          img: require("../../assets/img/productList/b2b2cMail/details_advantage_icon_5.png"),
          title: "迭代快",
          text:
            "开发团队将一直对系统进行深耕，不断收集各类用户需求，快速新增功能、修复漏洞、升级体验度",
        },
        {
          id: 6,
          img: require("../../assets/img/productList/b2b2cMail/details_advantage_icon_6.png"),
          title: "可定制",
          text:
            "提供定制化服务，可满足各行业、各类客户的需求真正将商城系统功能最大化，成为您的最大助力",
        },
      ],
      opeList: [
        {
          id: 1,
          img: require("../../assets/img/productList/b2b2cMail/operating_img_1.jpg"),
          title: "自营消费收入",
          subTitle: "PROPRIETARY",
        },
        {
          id: 2,
          img: require("../../assets/img/productList/b2b2cMail/operating_img_2.jpg"),
          title: "广告位收入",
          subTitle: "ADVERTISING",
        },
        {
          id: 3,
          img: require("../../assets/img/productList/b2b2cMail/operating_img_3.jpg"),
          title: "商品类目佣金",
          subTitle: "COMMISSION",
        },
        {
          id: 4,
          img: require("../../assets/img/productList/b2b2cMail/operating_img_4.jpg"),
          title: "资金沉淀收入",
          subTitle: "CAPITAL DEPOSIT",
        },
        {
          id: 5,
          img: require("../../assets/img/productList/b2b2cMail/operating_img_5.jpg"),
          title: "客服系统租金",
          subTitle: "CUSTOMER SERVICE",
        },
      ],
      functionList: [
        {
          id: 1,
          title: "商品展示及交易",
          list: [
            {
              id: 1,
              name: "商品展示",
              text:
                " 澄果B2B2C商城商品展示拥有多种列表样式，多维度排序与筛选，可让用户更加精准全面的的了解的商品的基本情况。详情页采用当下主流的电商布局形式，同时进行澄果的风格化设计，既保证了界面的美观，同时也确保了较高的实用性与较好的用户体验效果。",
              img: require("../../assets/img/productList/b2b2cMail/fc/1.jpg"),
            },
            {
              id: 2,
              name: "分类与搜索",
              text:
                "澄果B2B2C商城拥有功能全面的多级分类展示，可确保商品分类的展示效果。商品搜索可对于全局商品进行快速搜索，采用高用户体验度的检索方式，确保用户可以快速精准的找到自己所有需要的商品。同时平台可更加运用需求为用户推送搜索关键词，系统也会对用户近期的搜索记录进行保存，有效的提高到了用户的使用体验。",
              img: require("../../assets/img/productList/b2b2cMail/fc/2.jpg"),
            },
            {
              id: 3,
              name: "购物车",
              text:
                "澄果B2B2C商城购物车支持多商铺跨店结算。界面简约清晰易操作，学习成本较低。同时在购物底部可以向用户展示平台推荐或者用户感兴趣的相关商品",
              img: require("../../assets/img/productList/b2b2cMail/fc/3.jpg"),
            },
            {
              id: 4,
              name: "收藏与评价",
              text:
                "  澄果B2B2C商城支持用户对商品进行评价与收藏。用户可对心仪的商品进行收藏并自主管理自己的收藏夹。浏览商品时可查看其他一购买该商品的用户多发布的评价评分以及商家对评价的回复情况，帮助用户更加真实的了解商品。在用户完成交易后也可发布自己对商品的评价评分。",
              img: require("../../assets/img/productList/b2b2cMail/fc/4.jpg"),
            },
            {
              id: 5,
              name: "在线支付",
              text:
                "澄果B2B2C商城支持当前主流的各类支付方式（如支付宝、微信支付等），并可根据商城系统的所在的不同平台的要求，做出相应的支付方式的调整，确保线上支付顺利进行，并且通过多种技术方式为支付安全保驾护航。",
              img: require("../../assets/img/productList/b2b2cMail/fc/5.jpg"),
            },
          ],
        },
        {
          id: 2,
          title: "店铺运营",
          list: [
            {
              id: 1,
              name: "店铺配置",
              text:
                "商家可对自己的商品首页进行自己配置。可修改店铺首页头部图片，可根据自己的需求对店铺首页轮轮播图、楼层进行配置。可配置出一个拥有专属风格的、外观独立的店铺。",
              img: require("../../assets/img/productList/b2b2cMail/fc/6.jpg"),
            },
            {
              id: 2,
              name: "商品管理",
              text:
                "  商品管理主要分为两部分，一是商家对自己店铺的商品管理，二是平台对对整个平台上的商品进行统一的管控。 商家在自己的店铺能进行商品上传、信息编辑管理、库存管理、上下架管理、价格管理等。 平台端可查看所有商品的相关信息与数据，并根据管理需求可对商品进行上下架管理，对于上新商品需平台进行审核。",
              img: require("../../assets/img/productList/b2b2cMail/fc/7.jpg"),
            },
          ],
        },
        {
          id: 3,
          title: "营销活动",
          list: [
            {
              id: 1,
              name: "优惠券",
              text:
                " 优惠券是商城不可获取的一项功能。平台可制定各类满减券、折扣券、全场券、类目券等，并可根据需求配置卡券的使用规则（如期限、发行量、领取限制等)、卡券面额等。店铺可根据自身的运营需要来选择适合自身的卡券使用，而达到为平台与店铺引流、纳新、提升销量、增加用户粘性等目的。",
              img: require("../../assets/img/productList/b2b2cMail/fc/8.jpg"),
            },
            {
              id: 2,
              name: "秒杀活动",
              text:
                "秒杀是一项可以快提高单品销量、打造爆款，增加用户活跃度的功能。为此澄果商城也打造出一套秒杀系统。      活动配置自主性强，可根据不同需求进行秒杀配置，同时专业的合理的程序设计确保秒杀活动的顺利进行。",
              img: require("../../assets/img/productList/b2b2cMail/fc/9.jpg"),
            },
            {
              id: 3,
              name: "其他营销活动",
              text:
                "澄果B2B2C商城拥有较多的营销活动功能，如品牌推荐、人气推荐、新品推荐、专题推荐、入门搜索、广告轮播图等。我们依然致力于不断开发更多营销活动，不断丰富着“营销活动库”，同时也可根据不同的需求进行功能定制，为营销提供全力支持。",
              img: require("../../assets/img/productList/b2b2cMail/fc/10.jpg"),
            },
          ],
        },
        {
          id: 4,
          title: "其他功能",
          list: [
            {
              id: 1,
              name: "会员系统",
              text:
                "      澄果B2B2C商城拥有一套功能丰富的会员系统，为会员用户提供一个超值的购物环境，为平台提供一个高质量用户的专属空降。用户可根据自己的不同的需求选择不同的会员类型，享受不同的会员服务。成为平台会员后即可享受会员折扣购物、会员专属商品、会员专属卡券等专享服务。",
              img: require("../../assets/img/productList/b2b2cMail/fc/11.jpg"),
            },
            {
              id: 2,
              name: "订单系统",
              text:
                "      澄果B2B2C商城拥有一套完善全面的订单系统，在用户下单的同时，用户、商户、平台方都会生成统一的订单，对平台中不同身份不同角色赋予相应的操作与查看权限。",
              img: require("../../assets/img/productList/b2b2cMail/fc/12.jpg"),
            },
            {
              id: 3,
              name: "售后系统",
              text:
                "      澄果B2B2C商城的售后系统支持已售出商品的退货、换货、退款等售后操作。售后流程由用户发起，商家进行售后申请的进一步处理，平台进行监督管控及必要时的介入，确保平台交易的公平性与稳定性。平台可设置退换货原因快捷选项，及方便用户发起售后请求，也可提升平台与商家的管理效率。",
              img: require("../../assets/img/productList/b2b2cMail/fc/13.jpg"),
            },
            {
              id: 4,
              name: "商户管理",
              text:
                "   商城系统管理员可对平台上所有的商户进行统一管理。新入驻商户的审核、查看商户资料、编辑商户信息等。",
              img: require("../../assets/img/productList/b2b2cMail/fc/14.jpg"),
            },
            {
              id: 5,
              name: "店铺管理",
              text:
                "  澄果B2B2C商城支持“一户多店”，一个商户可拥有多家店铺。系统管理员可对平台内的所有店铺进行统一的管理，查看店铺信息以、授权商品分类等。",
              img: require("../../assets/img/productList/b2b2cMail/fc/15.jpg"),
            },
            {
              id: 6,
              name: "权限管理",
              text:
                "对登录总管理平台的用户进行角色、权限的分配。可创登录平台的新账号、对账号进行用户名和密码修改。创建登录平台的各类角色，并为角色配置相应的操作权限，再为各个账号选型所属角色，从而完成登录账号的权限的分配。",
              img: require("../../assets/img/productList/b2b2cMail/fc/16.jpg"),
            },
            {
              id: 7,
              name: "审核管理",
              text:
                "为全部整个商城系统的安全顺利运行，尽可能的降低平台风险，因此商城管理端对店铺、C端用户设置必要的审核管控，如，新店入驻审核、商品发布审核、品牌审核、售后审核等。及可以规避不必要的风险也在最大程度确保商城的梳理运行。",
              img: require("../../assets/img/productList/b2b2cMail/fc/17.jpg"),
            },
            {
              id: 8,
              name: "数据统计",
              text:
                "平台会对订单、卡券、会员系统等各类功能生成数据统计报表，方便对商品整体、店铺运营情况进行分析，为运营规划提供实际数据的支撑。",
              img: require("../../assets/img/productList/b2b2cMail/fc/18.jpg"),
            },
          ],
        },
      ],
      seriveList: [
        {
          id: 1,
          img: require("../../assets/img/productList/b2b2cMail/details_case_service_1.png"),
          title: "定制化服务",
          text:
            "我们将会根据您的实际需求为您提供定制化的系统开发服务，是整个商城系统更加符合您的需求，达到价值的最大化",
        },
        {
          id: 2,
          img: require("../../assets/img/productList/b2b2cMail/details_case_service_2.png"),
          title: "完善的维保服务",
          text:
            "正式上线后，我们将长时间持续的关注您的系统运行情况，确保在短时间内高效解决系统故障与问题，为您的系统保驾护航",
        },
        {
          id: 3,
          img: require("../../assets/img/productList/b2b2cMail/details_case_service_3.png"),
          title: "运营服务",
          text:
            "专业且经验丰富的运营团队，为您提供不同程度的运营服务，使您的商城系统快速完成商业转化，获得实际的价值与收益。",
        },
        {
          id: 4,
          img: require("../../assets/img/productList/b2b2cMail/details_case_service_4.png"),
          title: "使用培训",
          text:
            "专业培训团队讲解整个系统的使用规则与操作方法，同时配有使用说明、用户手册，使您及您的团队快速上手，减少学习成本",
        },
      ],
    };
  },
  methods: {
    chooseFunctionItem(e, f) {
      this.Findex = e;
      this.FitemIndex = f;
    },
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.$swiper;
    },
  },
};
</script>

<style lang="scss" src="../../assets/css/productList.scss" scoped>
</style>

<style lang="scss" scoped>
.banner {

  background-image: url("../../assets/img/productList/b2b2cMail/banner_details.jpg");

}

.summary {
  padding: 60px 0;
  .introduce {
    display: flex;
    justify-content: center;
    margin: 30px 0 50px 0;
    div {
      font-size: 18px;
      color: #515151;
      height: 22px;
      line-height: 22px;
      margin-right: 80px;
      &:last-child {
        margin-right: 0;
      }
      &::before {
        content: "";
        display: inline-block;
        width: 22px;
        height: 22px;
        background: url("../../assets/img/productList/b2b2cMail/details_introduce_icon.png");
        vertical-align: -2px;
        margin-right: 7px;
      }
    }
  }
  .introduce_img {
    width: 600px;
    font-size: 24px;
    .bottom {
      height: 70px;
      line-height: 70px;
      border-radius: 0px 0px 3px 3px;
      text-align: center;
    }
    &:first-child {
      background-color: #2196f3;
      color: #ffffff;
    }
    &:last-child {
      color: #6b6456;
      background-color: #dfc99e;
    }
  }
  .btn_lgd {
    width: 220px;
    margin: 55px auto 0 auto;
  }
}
.adt {
  padding: 45px 0;
  width: 100%;
  height: 770px;
  background: url("../../assets/img/productList/b2b2cMail/details_advantage_bg.jpg")
    no-repeat;
  background-color: #f6f9fc;
  background-position: center;
  .grid_group {
    width: 1190px;
    margin: 60px auto 0 auto;
    grid-template-rows: repeat(2, 260px);
    grid-template-columns: repeat(3, 350px);
    grid-gap: 70px;
    text-align: center;
    font-size: 16px;
    color: #616161;
    .title {
      font-size: 26px;
      font-weight: bold;
      color: #313131;
      margin: 18px 0;
    }
    .stp {
      width: 30px;
      height: 8px;
      background-color: #1b65fb;
      border-radius: 4px;
      margin: 0 auto 18px auto;
    }
  }
}
.mid {
  padding: 70px 0;
  background: url("../../assets/img/productList/b2b2cMail/details_bg.jpg");
  .btn_lgd {
    width: 110px;
    margin: 35px auto 54px auto;
  }
  .adv {
    margin-bottom: 68px;
  }
}
.mode_img {
  width: 208px;
  height: 330px;
  padding: 10px;
  background-color: #ffffff;
  border: solid 1px #e5e5e5;
  text-align: center;
  position: relative;
  top: 0;
  z-index: 2;
  transition: 0.2s;
  &::before {
    display: block;
    position: absolute;
    width: 228px;
    height: 20px;
    background: url("../../assets/img/productList/b2b2cMail/shadow_1.png");
    z-index: 1;
    bottom: -20px;
    content: "";
  }
  &:hover {
    border-color: #2196f3;
    top: -10px;
    &::before {
      background: url("../../assets/img/productList/b2b2cMail/shadow_2.png");
    }
  }
  .img {
    line-height: 0;
  }
  .ball {
    width: 22px;
    height: 22px;
    background-image: linear-gradient(30deg, #2196f3 0%, #1b64fb 100%);
    position: relative;
    top: -11px;
    left: calc(50% - 11px);
    border-radius: 50%;
  }
  .title {
    font-size: 26px;
    color: #000000;
    margin-top: 18px;
  }
  .sub {
    font-size: 16px;
    color: #919191;
  }
}
.fun {
  width: 100%;
  height: 700px;
  background: url("../../assets/img/productList/b2b2cMail/details_function_bg.jpg")
    no-repeat;
  background-position: center;
  .title_img {
    width: 40px;
    height: 44px;
    position: relative;
    top: -22px;
    left: calc(50% - 20px);
    background: url("../../assets/img/productList/b2b2cMail/details_function_title.png");
  }
  .title_text {
    font-size: 34px;
    color: #ffffff;
    text-align: center;
  }
  .function_group {
    display: flex;
    margin-top: 28px;
    .function_menu {
      width: 580px;
      padding: 30px 25px 0 25px;
      height: 520px;
      background-color: #f5f5f5;
      border-radius: 3px 0px 0px 3px;
      .menu_title {
        font-size: 16px;
        color: #313131;
        font-weight: bold;
      }
      .menu {
        margin-bottom: 26px;
        &:last-child {
          margin-bottom: 0;
        }
      }
      .menu_list {
        display: flex;
        flex-wrap: wrap;

        .menu_item {
          width: 110px;
          height: 40px;
          background-color: #ffffff;
          border-radius: 3px;
          font-size: 16px;
          line-height: 40px;
          color: #717171;
          position: relative;
          padding-left: 20px;
          margin-top: 10px;
          margin-right: 20px;
          &:nth-child(4n) {
            margin-right: 0;
          }
          &::before {
            width: 8px;
            height: 8px;
            background-color: #2196f3;
            border-radius: 4px;
            content: "";
            display: block;
            position: absolute;
            top: 16px;
            left: -4px;
          }
        }
        .active {
          background-color: #2196f3;
          color: #ffffff;
          &::before {
            background-color: #e8caa3;
          }
        }
      }
    }
    .function_introduce {
      width: 630px;
      height: 550px;
      background-color: #ffffff;
      border-radius: 0px 3px 3px 0px;
      .heade {
        margin-top: 20px;
        text-align: right;
        padding-right: 20px;
        .gold_title_right {
          position: relative;
          display: inline-block;
          .text {
            width: 100%;
            font-size: 30px;
            font-weight: bold;
            color: #000000;
            text-align: center;
            position: relative;
          }
          &::before {
            position: absolute;
            width: calc(100% + 45px);
            display: block;
            content: "";
            height: 17px;
            background-color: #e8caa3;
            border-radius: 8px 0px 0px 8px;
            top: 27px;
            right: -20px;
          }
        }
      }

      .img {
        width: 540px;
        margin-top: 42px;
        img {
          width: 100%;
          height: auto;
        }
      }
      .function_text {
        width: 581px;
        font-size: 16px;
        line-height: 30px;
        color: #515151;
        margin: 30px auto 0 auto;
      }
    }
  }
}
.serive {
  padding: 60px 0 80px 0;
  background-image: linear-gradient(0deg, #ffffff 0%, #f5faff 100%);
  .serive_card {
    margin-top: 46px;
    width: 225px;
    height: 320px;
    padding: 30px;
    background-color: #ffffff;
    border-radius: 10px;
    .img {
      width: 110px;
      margin: 0 auto;
    }
    .text {
      text-align: center;
      font-size: 16px;
      line-height: 30px;
      color: #717171;
      .bold {
        font-size: 24px;
        color: #000000;
        font-weight: bold;
        margin: 16px 0 28px 0;
      }
    }
  }
  .swiper {
    margin-top: 100px;
    .title {
      font-size: 26px;
      text-align: center;
      color: #515151;
      margin-bottom: 26px;
    }
    .slide_wrap {
      width: 1110px;
      height: 400px;
      display: flex;
      padding: 0 75px;
      .text {
        width: 560px;
      }
      .bule_title_left {
        font-size: 34px;
        font-weight: bold;
        color: #000000;
        position: relative;
        margin-bottom: 36px;
        .bule_title_text {
          position: relative;
          z-index: 2;
        }
        &::before {
          display: block;
          content: "";
          width: 240px;
          height: 16px;
          background-color: #2196f3;
          border-radius: 0px 5px 5px 0px;
          position: absolute;
          z-index: 1;
          top: 32px;
        }
      }
      .text_list {
        width: 549px;
        font-size: 16px;
        line-height: 26px;
        color: #818181;
        position: relative;
        margin-bottom: 48px;
        left: 11px;
        &:last-child {
          margin-bottom: 0;
        }
        &::before {
          display: block;
          position: absolute;
          left: -11px;
          top: 6px;
          content: "";
          width: 10px;
          height: 10px;
          background-color: #2196f3;
          border-radius: 5px;
        }
      }
      .img{
        width: 550px;
        img{
          width: 100%;
          height: auto;
        }
      }
    }
  }
}
</style>